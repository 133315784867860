<template>
    <section id="accueil">
      <div class="triangle_rose"></div>
     
     
        <body class="hold-transition login-page" style="background-image: url('img/login.png');">
          <div class="login-box">
            <div class="login-logo">
              <p style="color:white"><b>Goal Tracker</b></p>
            </div>
            <!-- /.login-logo -->
            <div class="card">
              <div class="card-body login-card-body">
                <p class="login-box-msg">Register</p>
  
                <form  @submit.prevent="submitRegisterInfo">
                    <div class="card-body row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        v-model="name"
                        class="form-control"
                        id="Name"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email Address</label>
                      <input
                        type="text"
                        v-model="email"
                        class="form-control"
                        id="department"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Mobile</label>
                      <input
                        type="text"
                        v-model="mobile"
                        class="form-control"
                        id="mobile"
                        placeholder="Enter mobile"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">department</label>
                     
                      <select
                        v-model="department"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                       <option v-for="valuedept in fetchDepart.DeptList" :key="valuedept.name">{{valuedept.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Password</label>
                      <input
                        type="text"
                        v-model="password"
                        class="form-control"
                        id="editpassword"
                        placeholder="Enter password"
                        require
                      />
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
                </form>
                <div class="social-auth-links text-center mb-3">
  
                  
                  
                   
                </div>
                <p class="mb-0">
                    <router-link  class="text-center" to="/"> 
                        I already have a Register
                  </router-link>
                </p>
                <!-- <button @click="$store.dispatch('login')" >Login</button> -->
  
              </div>
              <!-- /.login-card-body -->
            </div>
          </div>
          <!-- /.login-box -->
        </body>
        
      <div class="triangle_vert"></div>
    </section>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex"
  import axios from 'axios';
  export default {
      name:"TMSLogin",
      data() {
              return {
                  // for new tasks
                  loginCheck: false,
                  email: '',
                  password:'',
              };
          },
          computed: {
            ...mapActions(['GetUsers','fetchUserType','DeptList']),
            ...mapGetters(['fetchUsers','fetchDepart']),

        },
        mounted() {
       this.mountfunctioncall()
        },  
    methods: {  
        mountfunctioncall(){
          this.$store.dispatch('DeptList');
          this.$store.dispatch('fetchUserType');
          this.$store.dispatch('GetUsers');
        },
     async submitRegisterInfo()
      { 
        let that=this;  
        let dt='';
        let payload={
            sDate:dt,
            name:that.name,
            email:that.email,
            mobile:that.mobile,
            department:that.department,
            usertype:'user',
            password:that.password,
            status:'Active',
          }

          var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/users`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  if(res.status==201 || res.status==200){

                    that.$swal({
                  text: 'Register Successfully',
                  target: '#custom-target',
                  customClass: {
                    container: 'position-absolute'
                  },
                  toast: true,
                  position: 'top'
              });

              that.$router.push('/');


                  }
              })
              .catch(function (error) {
                  // handle error
                  that.$swal({
                  text: error.message,
                  target: '#custom-target',
                  customClass: {
                    container: 'position-absolute'
                  },
                  toast: true,
                  position: 'top'
              });
                  console.log(error);
              })
              .then(function () {
                  // always executed
                   
                  console.log('--------s-')
              });

         // that.$store.dispatch('InsertUsers',payload);

        //   that.$swal({
        //     text: 'Successfully Inserted record',
        //     target: '#custom-target',
        //     customClass: {
        //       container: 'position-absolute'
        //     },
        //     toast: true,
        //     position: 'top'
        // });

        
      }
  }
  
  };
  </script>
  <style scoped>
  body {
    padding: 0;
    margin: 0;
  }
  
  section#accueil {
    background: #ecf6f8;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  
  
  
  </style>
  