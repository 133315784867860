<template>
    <div>
        <h2><a target="_blank" href="https://www.npmjs.com/package/vue-pagination-2">Vue Pagination 2</a></h2>
  <p>Selected page: {{page}}</p>
  <ul>
    <li v-for="(record, index) of displayedRecords" :key="index">{{record}}</li>
  </ul>
  <pagination :records="records.length" v-model="page" :per-page="perPage" @paginate="callback">
  </pagination>

    </div>
</template>

<script>
    import Pagination from 'v-pagination-3';

    export default {
        components: {
    Pagination
  },
   data(){

       return {
           page: 1,
           perPage: 10,
           records: []
        }
    },
  methods: {
    callback: function(page) {
        console.log('---yo---',page)
      // no need for callback here since you have all your data loaded already
    }
  },
  computed: {
    displayedRecords() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.records.slice(startIndex, endIndex);
    }
  },
  created() {
    // here we simulate an api call that returns the complete list
    for (let i = 1; i <= 500; i++) {
      this.records.push(`Item ${i}`);
    }
  }
    }
</script>

<style lang="scss" scoped>

</style>