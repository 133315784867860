import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router' // <---
import store from './store/index'   
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//import adminlte scripts

import "admin-lte/dist/js/adminlte.min.js"
// import 'admin-lte/plugins/jquery/jquery.min.js'
// import "admin-lte/plugins/select2/js/select2.full.min.js"
// import "admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"


// import "admin-lte/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js"
//import "../node_modules/admin-lte/plugins/summernote/summernote-bs4.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
// import "admin-lte/plugins/daterangepicker/daterangepicker.js"
//mport "../node_modules/admin-lte/plugins/moment/moment.min.js"
// import "admin-lte/plugins/jquery-knob/jquery.knob.min.js"
//import "../node_modules/admin-lte/plugins/jqvmap/maps/jquery.vmap.usa.js"
//import "../node_modules/admin-lte/plugins/jqvmap/jquery.vmap.min.js"
// import "admin-lte/plugins/sparklines/sparkline.js"
//import "../node_modules/admin-lte/plugins/chart.js/Chart.min.js"
// import "admin-lte/plugins/jquery-ui/jquery-ui.min.js"
// import "admin-lte/plugins/inputmask/jquery.inputmask.min.js"
//import "../node_modules/admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js"
// import "admin-lte/plugins/bootstrap-switch/js/bootstrap-switch.min.js"
// import "admin-lte/plugins/bs-stepper/js/bs-stepper.min.js"
// import "admin-lte/plugins/dropzone/min/dropzone.min.js"
// import 'admin-lte/dist/js/adminlte.js'
// import 'admin-lte/dist/js/demo.js'
// import 'admin-lte/dist/js/pages/dashboard.js'
//import adminlte styles


import 'admin-lte/dist/css/adminlte.min.css'
// import "admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css"
// import "admin-lte/plugins/summernote/summernote-bs4.min.css"
// import "admin-lte/plugins/daterangepicker/daterangepicker.css"
// import "admin-lte/plugins/jqvmap/jqvmap.min.css"
// import "admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css"
// import "admin-lte/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css"
// import "admin-lte/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css"
// import "admin-lte/plugins/bs-stepper/css/bs-stepper.min.css"
// import "admin-lte/plugins/dropzone/min/dropzone.min.css"
// import "admin-lte/plugins/fontawesome-free/css/all.min.css"


import Vue3Storage from "vue3-storage";

// import "fullcalendar/dist/fullcalendar.min.css";
import "fullcalendar/main.min.css"
//createApp(App).mount('#app')
// const app=createApp(App)
const app = createApp({
    extends: App,
    created() {
      this.$store.dispatch('loadStoredState')
      this.$store.dispatch('loadUser')
    
      //  console.log('............----',this.$store.state.isLoggedIn);
      // let //check= localStorage.getItem("isLoggedIn");
      //   if(check==='true'){
        
      //     this.$router.push('/dashboard');

      //   }
      //   else
      //     {
      //       this.$router.push('/');
      //     }
      // or use `store` directly
      // store.dispatch('loadStoredState')
      // store.dispatch('loadUser')
    },
  })

  app.use(router)
  app.use(store)
  app.use(VueSweetalert2)
  app.use(Vue3Storage)
app.mount('#app')