<template>
    <div class="hello">
        <div class="container-fuild">
            <div class="conatiner">
                <center>

                    <button class="btn btn-success mt-2" @click="toggleWeekends">Toggle Weekends</button>
                </center>
            </div>
        </div>
      <FullCalendar :options="calendarOptions"></FullCalendar>
      <!-- The Modal -->
        <div class="modal" id="mymodal">
        <div class="modal-dialog">
            <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">
                <h4 class="modal-title">Modal Heading</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                Modal body..
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
            </div>

            </div>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  import FullCalendar from "@fullcalendar/vue3";
//   import fullcalendar from "@fullcalendar/vue3";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import timeGridPlugin from "@fullcalendar/timegrid";
  import interactionPlugin from "@fullcalendar/interaction";
  import { mapGetters,mapActions } from "vuex"

  export default {
    name: "HelloWorld",
    components: {
        FullCalendar,
    },
    mounted(){
        this.mountedcallfun()
    },
    computed: {
    ...mapActions(['GetTodoList',]),
    ...mapGetters(['fetchTODO']),
    },
    data() {
        return {
        todayStr : new Date().toISOString().replace(/T.*$/, ""), // YYYY-MM-DD of today
        calendarOptions: {
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin, // needed for dateClick
          ],
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          },
          initialView: "dayGridMonth",
        //   dateClick: this.handleDateClick,
          initialEvents: [
                        {
                            taskid: 0,
                            title: "All-day event",
                            start: '2022-10-07'
                        },
                        {
                            taskid: 1,
                            title: "Timed event",
                            start: '2022-10-08'
                        }
                        ], // alternatively, use the `events` setting to fetch from a feed
                        events: [
          { title: 'event 1', date: '2022-10-08' },
          { title: 'event 2', date: '2022-10-08' }
        ],
          editable: true,
          selectable: true,
          selectMirror: true,
          dayMaxEvents: true,
          weekends: true,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventsSet: this.handleEvents
          /* you can update a remote database when these fire:
          eventAdd:
          eventChange:
          eventRemove:
          */
        },
        currentEvents: [],
      };
    },
    props: {
      msg: String,
    },
    methods: {
        open() {
        this.$emit('open');
      },
      close() {
        this.$emit('close');
      },
        handleDateSelect(selectInfo) {
            document.getElementById('mymodal').click();
        this.open()
      let title = prompt('Please enter a new title for your event')
      let calendarApi = selectInfo.view.calendar

      calendarApi.unselect() // clear date selection

      if (title) {
        calendarApi.addEvent({
          id: 1,
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay
        })
      }
    },
    handleEventClick(clickInfo) {
        
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },
    handleEvents(events) {
      this.currentEvents = events;
    },
    //     handleDateClick: function(arg) {
    //   alert('date click! ' + arg.dateStr)
    // },
       async mountedcallfun(){
           await this.$store.dispatch('GetTodoList',localStorage.getItem('userID'));
            // this.initialEvents=this.fetchTODO;
        },
      toggleWeekends: function () {
        this.calendarOptions.weekends = !this.calendarOptions.weekends; // toggle the boolean!
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  </style>
  