<template>
    <div>
        <p>Page Not Found</p>

    </div>
    <div>
        <router-link to="/"> Go To Home Page</router-link>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>