import { createStore } from 'vuex'
// import { isLoggedIn, logOut, logIn, ManagerMenu,UserMenu,DirectoreMenu,isManagerIn,isUserIn,isDirectoreIn } from '../shared/utils/auth'
import { isLoggedIn, logOut, logIn, UserMenu,isManagerIn,isUserIn,isDirectoreIn } from '../shared/utils/auth'
import axios from 'axios';
const store = createStore({
    state(){
        return{
          base_url:'https://tmsapipost.areteonline.in/',
          managerMenu:[],
          MainLogin:'',
            isLoggedIn: false,
            LoginUser:{},
            DeptList:[],
            editDeptList:[],
            Usertype:[],
            editUserType:[],
            Users:[],
            edituser:[],
            projects:[],
            editProject:[],
            TaskList:[],
            todolist:[],
            inprogresslist:[],
            testinglist:[],
            donelist:[],

            ManagerTODO:[],
            Managerinprogresslist:[],
            Managertestinglist:[],
            Managerdonelist:[],

            ManagerTEAMTODO:[],
            ManagerTEAMinprogresslist:[],
            ManagerTEAMtestinglist:[],
            ManagerTEAMdonelist:[],

            ManagerDashboardTEAMTODO:[],
            ManagerDashboardTEAMinprogresslist:[],
            ManagerDashboardTEAMtestinglist:[],
            ManagerDashboardTEAMdonelist:[],

            UserTaskDetails:[],
            Role:'',
            isManagerIn:false,
            isUserIn:false,
            isDirectorIn:false,
            DirectoreEntity:[],
            rowEntity:[],
            subProjects:[],
            DepartmentUser:[],
            rowSubProject:[],
            checkList:[],
            taskEmail:[],
            TaskActionList:[],
            loginWiseUserList:[],
            TaskCommentsList:[],
            rowed:[],
            EntityDash:[],
            UserwiseTasklist:[],

        }
    },
    mutations:{
        setUser(state, payload) {
            state.user = payload
          },
        setLoggedIn(state, payload) {
            state.isLoggedIn = payload
          },
        increment(state){
            state.count++;
        },
        updatelogindata(state,payload){
            state.LoginUser=payload;
        },
        updateDepartList(state,payload){
          state.DeptList=payload;
        },
        getDepartrecord(state,payload)
        {
          state.editDeptList=payload;
        },
        getUserType(state,payload){
          state.Usertype=payload
        },
        updateusertypeList(state,payload){
          state.Usertype=payload
        },
        getusertyperecord(state,payload){
          state.editUserType=payload;
        },
        getUsersdata(state,payload){
          state.Users=payload;
        },
        getusersrecord(state,payload){
          state.Users=payload;
        },
        getUsersOnerRow(state,payload){
          state.edituser=payload;
        },
        getprojects(state,payload){
          state.projects=payload;
        },
        getprojectsROW(state,payload){
          state.editProject=payload;
        },
        getTasklist(state,payload){
          state.TaskList=payload;
        },
        getTODO(state,payload){
          state.todolist=payload;
        },
        getInprogress(state,payload){
          state.inprogresslist=payload;
        },
        getTesting(state,payload){
          state.testinglist=payload;
        },
        getDone(state,payload){
          state.donelist=payload;
        },
        getManagerTODO(state,payload){
          state.ManagerTODO=payload;
        },
        getManagerInprogress(state,payload){
          state.Managerinprogresslist=payload;
        },
        getManagerTesting(state,payload){
          state.Managertestinglist=payload;
        },
        getManagerDone(state,payload){
          state.Managerdonelist=payload;
        },
        getManagerTEAMTODO(state,payload){
          state.ManagerTEAMTODO=payload;
        },
        getManagerTEAMInprogress(state,payload){
          state.ManagerTEAMinprogresslist=payload;
        },
        getManagerTEAMTesting(state,payload){
          state.ManagerTEAMtestinglist=payload;
        },
        getManagerTEAMDone(state,payload){
          state.ManagerTEAMdonelist=payload;
        },

        getDashboardManagerTEAMTODO(state,payload){
          state.ManagerDashboardTEAMTODO=payload;
        },
        getDashboardManagerTEAMInprogress(state,payload){
          state.ManagerDashboardTEAMinprogresslist=payload;
        },
        getDashboardManagerTEAMTesting(state,payload){
          state.ManagerDashboardTEAMtestinglist=payload;
        },
        getDashboardManagerTEAMDone(state,payload){
          state.ManagerDashboardTEAMdonelist=payload;
        },



        getUserTask(state,payload){
          state.UserTaskDetails=payload;
        },
        MutationNotActiveUser(state,payload){
          state.NotActiveUser=payload;
        },
        setmenu(state,payload){
         // console.log('------action',payload)
          state.Role=payload;
        },
        setManagerIn(state, payload) {
          state.isManagerIn = payload
        },
        setUserIn(state, payload) {
          state.isUserIn = payload
        },
        setDirectorIn(state, payload) {
          state.isDirectorIn = payload;
        },
        directEntity(state,payload){
          state.DirectoreEntity=payload;
        },
        RowedEntity(state,payload){
          state.rowEntity=payload;
        },
        StoreSprojects(state,payload){
          state.subProjects=payload;
        },
        DepartWiseUSER(state,payload){
          state.DepartmentUser=payload;
        },
        RowedSubProjects(state,payload){
          state.rowSubProject=payload;
        },
        getcheckForTask(state,payload){
          state.checkList=payload;
        },
        getemailUser(state,payload){
          state.taskEmail=payload;
        },
        getActionForTask(state,payload){
          state.TaskActionList=payload;
        },
        getLoginWiseUser(state,payload){
          state.loginWiseUserList=payload;
        },
        getCommentsForTask(state,payload){
          state.TaskCommentsList=payload;
        },
        setMainLogin(state,payload)
        {
          state.MainLogin=payload;
        },
        getEntityDashboard(state,payload){
          state.EntityDash=payload;
        },
        getUserWiseTasklist(state,payload){
          state.UserwiseTasklist=payload;
        },
        getActionForedTask(state,payload){
          state.rowed=payload;
        },
      

    },
    actions:{
      menuAction({commit}){
        let role=localStorage.getItem('Role');

        if(role=='manager'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='User'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Administrator'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Director'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }

      },
        loadStoredState(context) {
            context.commit('setLoggedIn', isLoggedIn())
            context.commit('setManagerIn', isManagerIn())
            context.commit('setUserIn', isUserIn())
            context.commit('setDirectorIn', isDirectoreIn())
            // context.commit('setUsername')
          },
          login({ dispatch },payload) {
            // simulate login
            dispatch('loadUser',payload)
          },
          logout({ commit }) {
            localStorage.setItem("userID",null);
            localStorage.setItem("Role",'');
            commit('setUser', {})
            commit('setLoggedIn', false)
            logOut()
          },
          async loadUser({ commit, dispatch },payload) {
            console.log('------loaduser----');
            if (!isLoggedIn()) {
              try {
                  var optionAxios = {
                  headers: {
                              'Content-Type': 'multipart/form-data',
                          }                         }

              // Make a request for a user with a given ID
              await  axios.post('https://tmsapi.areteonline.in/api/users/string,string',payload,optionAxios)
                      .then(function (res) {
                          if(res.data.status==='true' || res.data.status==='True'){
                            
                            commit('setUser', res.data)
                            // localStorage.setItem("Role", res.data.data.userType);
                            localStorage.setItem("Role",'user');
                            localStorage.setItem("Username", res.data.data.name);
                          //  this.state.MainLogin= res.data.data.name;
                              // if(res.data.data.userType=='manager'){
                              //   ManagerMenu()
                              //   commit('setManagerIn', true)
                              //   logIn()
                              //   commit('setLoggedIn', true)
                                 
                              //   commit('setUserIn', false)
                              //   commit('setDirectorIn', false)
                                 
                              //   localStorage.setItem("userID", res.data.data.userId);
                              //   localStorage.setItem("department", res.data.data.department);

                              // }
                              //else if(res.data.data.userType=="User"){
                                UserMenu()
                                commit('setMainLogin', res.data.data.name)
                                commit('setUserIn', true)
                                logIn()
                                commit('setLoggedIn', true)
                                commit('setManagerIn', false)
                                commit('setManagerIn', false)
                               localStorage.setItem("userID", res.data.data.userId);
                               localStorage.setItem("department", res.data.data.department);

                             // }
                              // else if(res.data.data.userType=="Director"){
                              //   DirectoreMenu()
                              //   commit('setDirectorIn', true)
                              //   logIn()
                              //   commit('setLoggedIn', true)
                              //   commit('setManagerIn', false)
                              //   commit('setUserIn', false)
                              //  localStorage.setItem("userID", res.data.data.userId);
                              //  localStorage.setItem("department", res.data.data.department);

                              // }
                              // else if(res.data.data.userType=="Administrator"){
                              //     console.log('--');
                              // }
                            // handle success
                           
                            
                          }else
                          {
                            dispatch('logout')
                          }
                      })
                      .catch(function (error) {
                          // handle error
                          console.log(error);
                      })
                      .then(function () {
                          // always executed
                          console.log('---------')
                      });

               // const user = (await axios.get('https://tmsapi.areteonline.in/api/deps/')).data
              
              } catch (error) {
                dispatch('logout')
              }
            }
          },
       async logincheck({commit},payload){
            const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${payload}`)
            const data = response.data;
            console.log(data,'----------------test');
            commit('updatelogindata',data);
        },
        async AddDepartment({commit,dispatch},payload){
               // console.log('---add-',payload)
           var optionAxios = {
                  headers: {
                              'Content-Type': 'multipart/form-data'
                          }                         }

            axios.post('https://tmsapi.areteonline.in/api/deps', {
                      name: payload
                    },optionAxios)
                    .then(function (response) {
                     // console.log(response);
                      commit('updateDepartList',response.data);

                      dispatch('DeptList')

                    })
                    .catch(function (error) {
                      console.log(error);
                    });


        },
        async DeptList({commit}){
          const response = await axios.get(`https://tmsapi.areteonline.in/api/deps`)
          const data = response.data;
          // console.log(data,'-----New list-----------depts');
          commit('updateDepartList',data);
      },
      async getRecordDeprt({commit},payload)
      {
        // console.log('----------paylo',payload);
        const response = await axios.get(`https://tmsapi.areteonline.in/api/deps/${payload}`)
        const data = response.data;
        // console.log(data,'----------------depts');
        commit('getDepartrecord',data);
      },
      async EditDepartment({commit,dispatch},payload){

        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/deps/${payload.id}`,{depid:payload.id,name:payload.name},optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getDepartrecord',res);
                  dispatch('DeptList');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });


      },
     async fetchUserType({commit}){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/usertypes`)
        const data = response.data;
        // console.log('=====type===',data);
        commit('getUserType',data);
      },
      async addUserType({commit,dispatch},payload){
        // console.log('---add-',payload)
        var optionAxios = {
               headers: {
                           'Content-Type': 'multipart/form-data'
                       }                         }

         axios.post('https://tmsapi.areteonline.in/api/usertypes', {
                   type: payload
                 },optionAxios)
                 .then(function (response) {
                  //  console.log(response);
                   commit('updateusertypeList',response.data);

                   dispatch('fetchUserType')

                 })
                 .catch(function (error) {
                   console.log(error);
                 });


      },
      async GetIdwiseUserType({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/usertypes/${payload}`)
        const data = response.data;
        // console.log(data,'---------------idwiserecord');
        commit('getusertyperecord',data);
      },
      async updateUsertype({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/usertypes/${payload.utid}`,{utid:payload.utid,type:payload.type},optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getusertyperecord',res);
                  dispatch('fetchUserType');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async GetUsers({commit}){
         var optionAxios = {
                  headers: {
                              'Content-Type': 'multipart/form-data',
                          }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/users`,optionAxios)
        const data = response.data;
        console.log('=====getUsers===');
        commit('getUsersdata',data);
      },
      async InsertUsers({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/users`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getusersrecord',res);
                  dispatch('GetUsers');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async getUsersdetails({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/users/${payload}`)
        const data = response.data;
        commit('getUsersOnerRow',data);
      },
      async updateUsersdetails({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/users/${payload.userId}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getusersrecord',res);
                  dispatch('GetUsers');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async fetchProjects({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/projects/getbyprogress/${payload}`)
        const data = response.data;
        commit('getprojects',data);
      },
      async insertProject({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/projects`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getprojects',res);
                  dispatch('fetchProjects',localStorage.getItem('userID'));
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async getProjectID({commit},payload){

        const response = await axios.get(`https://tmsapi.areteonline.in/api/projects/${payload}`)
        const data = response.data;
        commit('getprojectsROW',data);
      },
      async updateProject({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                    'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/projects/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getusersrecord',res);
                  dispatch('fetchProjects',localStorage.getItem('userID'));
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async TaskInsert({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/Tasklists`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('GetTodoList',localStorage.getItem('userID'));
                  dispatch('GetInprogressList',localStorage.getItem('userID'));
                  dispatch('GetTestingList',localStorage.getItem('userID'));
                  dispatch('GetDoneList',localStorage.getItem('userID'));
                  dispatch('ActionUserWiseTaskList',localStorage.getItem('userID'));
                  dispatch('fetchDashboardEntity',localStorage.getItem('userID'));
                  dispatch('GetSearchByUserList',localStorage.getItem('userID'));
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async GetTodoList({commit},payload){

        try {
          var optionAxios = {
            headers: {
                      'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/todo/${payload}`,optionAxios)
          const data = response.data;
          commit('getTODO',data);
      
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getTODO',data);

      }

        

      },
      async GetInprogressList({commit},payload){

      try {
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/inprogress/${payload}`,optionAxios)
          const data = response.data;
          commit('getInprogress',data);
      
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getTODO',data);

      }


        
      },
      async GetTestingList({commit},payload){

      try {
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/reviews/${payload}`,optionAxios)
          const data = response.data;
          commit('getTesting',data);
      
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getTODO',data);

      }

      
      },
      async GetDoneList({commit},payload){

        
      try {
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/done/${payload}`,optionAxios)
        const data = response.data;
        commit('getDone',data);
        // Work with the response...
    } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getTODO',data);

    }

      
      },
      async getTaskdetailsUser({commit},payload){
        try {
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapipost.areteonline.in/api/tasklists/GetbyID/${payload}`,optionAxios)
          const data = response.data;
          commit('getUserTask',data);
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getUserTask',data);
  
      }

       
      },
     async updateTaskListTodo({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data',
                          'Accept': 'text/plain'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/tasklists/${payload.taskid}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getTasklist',res);
                  dispatch('GetTodoList',localStorage.getItem('userID'));
                  dispatch('GetInprogressList',localStorage.getItem('userID'));
                  dispatch('GetTestingList',localStorage.getItem('userID'));
                  dispatch('GetDoneList',localStorage.getItem('userID'));
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });


      },
      async GetNotActiveUserList({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Users/Getbydep/${payload}`)
        const data = response.data;
        commit('MutationNotActiveUser',data);

      },
      async ActionEntity({commit}){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Entytabs`)
        const data = response.data;
        commit('directEntity',data);
      },
      async addEntity({commit,dispatch},payload){

        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/Entytabs`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('directEntity',res);
                  dispatch('ActionEntity');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });


      },
      async fetchEntityrow({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Entytabs/${payload}`)
        const data = response.data;
        commit('RowedEntity',data);
      },
      async UpdateEntity({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/Entytabs/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('directEntity',res);
                  dispatch('ActionEntity');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async ActionSubProject({commit}){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects`)
        const data = response.data;
        commit('StoreSprojects',data);
      },
      async DepartUser({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Users/Getbydepactive/${payload}`)
        const data = response.data;
        commit('DepartWiseUSER',data);
      },
      async addSubProjects({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/sprojects`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('StoreSprojects',res);
                  dispatch('ActionSubProject');
                  dispatch('GetManagerTodoList');
                  dispatch('GetManagerInprogressList');
                  dispatch('GetManagerTestingList');
                  dispatch('GetManagerDoneList');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
    async fetchSubprojectrow({commit},payload){
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects/${payload}`)
        const data = response.data;
        commit('RowedSubProjects',data);
      },
      async UpdateSProjects({commit,dispatch},payload){
       // console.log('****',payload);
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/sprojects/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('RowedSubProjects',res);
                  dispatch('ActionSubProject');
                  dispatch('GetManagerTodoList',localStorage.getItem('department'));
                  dispatch('GetManagerInprogressList',localStorage.getItem('department'));
                  dispatch('GetManagerTestingList',localStorage.getItem('department'));
                  dispatch('GetManagerDoneList',localStorage.getItem('department'));

              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async GetManagerTodoList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects/GetbyProgress/todo/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTODO',data);
      },
      async GetManagerInprogressList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects/GetbyProgress/inprogress/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerInprogress',data);
      },
      async GetManagerTestingList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects/GetbyProgress/reviews/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTesting',data);
      },
      async GetManagerDoneList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/sprojects/GetbyProgress/done/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerDone',data);
      },
      async GetTeamUserTODO({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/todo/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTEAMTODO',data);
      },
      async GetTeamUserINPROGRESS({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/inprogress/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTEAMInprogress',data);
      },
      async GetTeamUserTESTING({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/reviews/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTEAMTesting',data);
      },
      async GetTeamUserDONE({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/done/${payload}`,optionAxios)
        const data = response.data;
        commit('getManagerTEAMDone',data);
      },
      // 
      async GetMDashboardTodoList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/todo/${payload}`,optionAxios)
        const data = response.data;
        commit('getDashboardManagerTEAMTODO',data);
      },
      async GetMDashboardInprogressList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/inprogress/${payload}`,optionAxios)
        const data = response.data;
        commit('getDashboardManagerTEAMInprogress',data);
      },
      async GetMDashboardTestingList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/reviews/${payload}`,optionAxios)
        const data = response.data;
        commit('getDashboardManagerTEAMTesting',data);
      },
      async GetMDashboardDoneList({commit},payload){
        var optionAxios = {
          headers: {
                          'Accept': 'text/plain'
                  }                         }
        const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/GetbyProgress/done/${payload}`,optionAxios)
        const data = response.data;
        commit('getDashboardManagerTEAMDone',data);
      },
      async updateManagerTaskListTodo({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data',
                          'Accept': 'text/plain'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/tasklists/${payload.taskid}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getTasklist',res);
                  dispatch('GetMDashboardTodoList',localStorage.getItem('userID'));
                  dispatch('GetMDashboardInprogressList',localStorage.getItem('userID'));
                  dispatch('GetMDashboardTestingList',localStorage.getItem('userID'));
                  dispatch('GetMDashboardDoneList',localStorage.getItem('userID'));
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });


      },
      async getCheckListdetailsUser({commit},payload){

        try {
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/checklists/Getbytsk/${payload}`,optionAxios)
          const data = response.data;
          commit('getcheckForTask',data);
  
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getcheckForTask',data);
  
      }

       
       
      },
      async updateCheckListupdate({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/checklists/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('getCheckListdetailsUser',payload.tasklistid);
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
     async InsertCheckListupdate({commit,dispatch},payload)
      {
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/checklists`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('getCheckListdetailsUser',payload.tasklistid);
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async updateTaskDetails({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/tasklists/${payload.taskid}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('GetTodoList',localStorage.getItem('userID'));
                  dispatch('GetInprogressList',localStorage.getItem('userID'));
                  dispatch('GetTestingList',localStorage.getItem('userID'));
                  dispatch('GetDoneList',localStorage.getItem('userID'));
                  dispatch('ActionUserWiseTaskList',localStorage.getItem('userID'));
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async SendTaskAssign({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapi.areteonline.in/api/email`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('GetTodoList',localStorage.getItem('userID'));
                  dispatch('GetInprogressList',localStorage.getItem('userID'));
                  dispatch('GetTestingList',localStorage.getItem('userID'));
                  dispatch('GetDoneList',localStorage.getItem('userID'));
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async getUserdata({commit},payload){

        try {
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/users/${payload}`,optionAxios)
          const data = response.data;
          commit('getemailUser',data);
  
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          let data=[];
          commit('getemailUser',data);
  
      }

     

      },
      async getActionListdata({commit},payload){
        //console.log(payload);
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Actionlists/getbytsk/${payload}`,optionAxios)
          const data = response.data;
          commit('getActionForTask',data);
      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getActionForTask',data);

    }
      

      },
      async InsertActionListTask({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/Actionlists`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('getActionListdata',payload.tasklistid);
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });
      },
      async GetSearchbyProjectTODO({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/getbyproject/${payload.searchproject}/todo/${payload.username}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          commit('getTODO',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getTODO',data);

    }

       

      },
      async GetSearchbyProjectINPROGRESS({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/getbyproject/${payload.searchproject}/inprogress/${payload.username}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          commit('getInprogress',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getInprogress',data);

    }

       
      },
      async GetSearchbyProjectTESTING({commit},payload){

        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/getbyproject/${payload.searchproject}/reviews/${payload.username}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          // commit('getInprogress',data);
          commit('getTesting',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getTesting',data);

    }

      

      },
      async GetSearchbyProjectDONE({commit},payload){

        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/getbyproject/${payload.searchproject}/done/${payload.username}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          // commit('getInprogress',data);
          // commit('getTesting',data);
          commit('getDone',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getDone',data);

    }

      
      },
      async GetSearchbyUserTODO({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/GetbyProgressuserlist/todo/${payload.username}/${payload.searchUser}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          commit('getTODO',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getTODO',data);

    }

      
      },
      async GetSearchbyUserINPROGRESS({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/GetbyProgressuserlist/inprogress/${payload.username}/${payload.searchUser}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          commit('getInprogress',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getInprogress',data);

    }

      
      },
      async GetSearchbyUserTESTING({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/GetbyProgressuserlist/reviews/${payload.username}/${payload.searchUser}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          // commit('getInprogress',data);
          commit('getTesting',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getTesting',data);

    }

       
      },
      async GetSearchbyUserDONE({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/GetbyProgressuserlist/done/${payload.username}/${payload.searchUser}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          // commit('getInprogress',data);
          // commit('getTesting',data);
          commit('getDone',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getDone',data);

    }

       
      },
      async GetSearchByUserList({commit},payload){

        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/Getbyuserlist/${payload}`,optionAxios)
          const data = response.data;
          console.log('**user list **',data);
          commit('getLoginWiseUser',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getLoginWiseUser',data);

    }

      
      },
      async getCommentsListdata({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/commentlists/getbytsk/${payload}`,optionAxios)
          const data = response.data;
          commit('getCommentsForTask',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getCommentsForTask',data);

    }

          //console.log(payload);
        
      },
      async InsertCommentsListTask({commit,dispatch},payload){

        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://tmsapipost.areteonline.in/api/commentlists`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                  commit('getActionForedTask',res);
                  dispatch('getCommentsListdata',payload.tasklistid);
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async fetchDashboardEntity({commit},payload){

        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/Getbyentitylist/${payload}`,optionAxios)
          const data = response.data;
          commit('getEntityDashboard',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('getEntityDashboard',data);

    }

      },
      async GetSearchbyEntityTODO({commit},payload){

        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/Getbyentity/${payload.searchEntity}/todo/${payload.username}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          commit('getTODO',data);
          } catch (err) {
            // Handle error
            // console.log(err);
            let data=[];
            commit('getTODO',data);
        }

      },
      async GetSearchbyEntityINPROGRESS({commit},payload){
            try{
              var optionAxios = {
                headers: {
                                'Accept': 'text/plain'
                        }                         }
              const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/Getbyentity/${payload.searchEntity}/inprogress/${payload.username}`,optionAxios)
              const data = response.data;
              // commit('getemailUser',data);
              commit('getInprogress',data);

              } catch (err) {
                // Handle error
                // console.log(err);
                let data=[];
                commit('getInprogress',data);

            }

      },
      async GetSearchbyEntityTESTING({commit},payload){
            try{
              var optionAxios = {
                headers: {
                                'Accept': 'text/plain'
                        }                         }
              const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/Getbyentity/${payload.searchEntity}/reviews/${payload.username}`,optionAxios)
              const data = response.data;
              // commit('getemailUser',data);
              // commit('getInprogress',data);
              commit('getTesting',data);

                } catch (err) {
                  // Handle error
                  // console.log(err);
                  let data=[];
                  commit('getTesting',data);

              }

      },
      async GetSearchbyEntityDONE({commit},payload){
          try{
            var optionAxios = {
              headers: {
                              'Accept': 'text/plain'
                      }                         }
            const response = await axios.get(`https://tmsapi.areteonline.in/api/tasklists/Getbyentity/${payload.searchEntity}/done/${payload.username}`,optionAxios)
            const data = response.data;
            // commit('getemailUser',data);
            // commit('getInprogress',data);
            // commit('getTesting',data);
            commit('getDone',data);

              } catch (err) {
                // Handle error
                // console.log(err);
                let data=[];
                commit('getDone',data);

            }

      },
      async ActionUserWiseTaskList({commit},payload){
        try{
          var optionAxios = {
            headers: {
                            'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://tmsapi.areteonline.in/api/Tasklists/Getbyuser/${payload}`,optionAxios)
          const data = response.data;
          // commit('getemailUser',data);
          // commit('getInprogress',data);
          // commit('getTesting',data);
          console.log('--------------action');
          commit('getUserWiseTasklist',data);

            } catch (err) {
              // Handle error
              // console.log(err);
              let data=[];
              commit('getUserWiseTasklist',data);

          }
      }


    },
    getters:{
        fetchData(state){
            return state;
        },
        fetchDepart(state){
          return state;
        },
        fetchRecordDepart(state){
          return state;
        },
        fetchUsers(state){
          return state.Users;
        },
        fetchProject(state){
          return state.editProject;
        },
        fetchTODO(state){
          return state.todolist;
        },
        fetchINPROGRESS(state){
          return state.inprogresslist;
        },
        fetchTESTING(state){
          return state.testinglist;
        },
        fetchDONE(state){
          return state.donelist;
        },
        fetchManagerTODO(state){
          return state.ManagerTODO;
        },
        fetchManagerINPROGRESS(state){
          return state.Managerinprogresslist;
        },
        fetchManagerTESTING(state){
          return state.Managertestinglist;
        },
        fetchManagerDONE(state){
          return state.Managerdonelist;
        },

        fetchManagerTEAMTODO(state){
          return state.ManagerTEAMTODO;
        },
        fetchManagerTEAMINPROGRESS(state){
          return state.ManagerTEAMinprogresslist;
        },
        fetchManagerTEAMTESTING(state){
          return state.ManagerTEAMtestinglist;
        },
        fetchManagerTEAMDONE(state){
          return state.ManagerTEAMdonelist;
        },


        MDashboardfetchTODO(state){
          return state.ManagerDashboardTEAMTODO;
        },
        MDashboardfetchINPROGRESS(state){
          return state.ManagerDashboardTEAMinprogresslist;
        },
        MDashboardfetchTESTING(state){
          return state.ManagerDashboardTEAMtestinglist;
        },
        MDashboardfetchDONE(state){
          return state.ManagerDashboardTEAMdonelist;
        },


        fetechUserTask(state){
          return state.UserTaskDetails;
        },
        fetchEntity(state){
          return state.DirectoreEntity;
        },
        fetchrowEntity(state){
          return state.rowEntity;
        },
        fetchSubproject(state){
          return state.subProjects;
        },
        fetchDepartWiseUser(state){
          return state.DepartmentUser;
        },
        fetchrowSubprojects(state){
          return state.rowSubProject;
        },
        fetchChecklist(state){
          return state.checkList;
        },
        getTaskEmailUser(state){
          return state.taskEmail;
        },
        getActionListDash(state){
          return state.TaskActionList;
        },
        getLoginWiseUser(state){
          return state.loginWiseUserList;
        },
        getCommentsListDash(state){
          return state.TaskCommentsList;
        },
        getDashboardWiseEntity(state){
          return state.EntityDash;
        },
        fetchUserwiseTasklist(state){
          return state.UserwiseTasklist;
        }


    }

});

export default store;