<template>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Department</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Department</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
     
        <!-- /.card -->
        <div class="card row">
          <div class="card-header">
            <h3 class="card-title">Department List</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add Department</button>
              <!-- <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button> -->
            
            </div>
          </div>
          <!-- /.card-header -->
            <div class="">
              <table id="tableComponent" class="table table-bordered table-striped text-center">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="value in fetchDepart.DeptList" :key="value">
                        <td class="text-center">{{value.depid}}</td>
                        <td class="text-center">{{value.name}}</td>
                        <td class="text-center"><button type="button"  data-toggle="modal" data-target="#modal-default" @click="editDepert(value.depid)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button></td>
                    </tr>

                  </tbody>
                
                </table>
             
            </div>
            <!-- /.row -->

          <!-- /.card-body -->
          <div class="card-footer">
          
          </div>
        </div>
     </div>
    </section> 
    <div class="modal fade" id="modal-default-addDepart">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add Department</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form v-on:submit.prevent="submitForm"  ref="textareaform">

                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Department Name</label>
                    <input type="text" v-model="departmentname" class="form-control" id="depsname" placeholder="Enter department Name" require>
                  </div>
              
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

    <div class="modal fade" id="modal-default" @close="showModal = false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Department</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editDepartment">

                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Department Name</label>
                    <input type="text" v-model="Editdepartmentname" class="form-control" id="depsname" placeholder="Enter department Name" require>
                  </div>
                  
              
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
             
              <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->   
</template>

<script>
 import { mapGetters } from "vuex"

  export default {
      data() {
          return {
            departmentname:'',
            Editdepartmentname:'',
            EditdepartmentID:'',
              columns: ['id', 'name'],
              tableData: [],
              options: {
                  headings: {
                      id: 'ID',
                      name: 'Name',
                  },
                  sortable: ['name', 'album'],
                  filterable: ['name', 'album']
              }
          }
      },
      computed: {
    ...mapGetters(['fetchDepart'])
      },
      mounted() {
        this.fetchDeprt()
      },
      methods: {
        close() {
        this.$emit('close');
      },
      async  editDepert(id)
        {

         await this.$store.dispatch('getRecordDeprt',id);
          this.EditdepartmentID=this.$store.state.editDeptList.depid;
          this.Editdepartmentname=this.$store.state.editDeptList.name;
        },
        editDepartment()
        {
          this.tableData= this.$store.dispatch('EditDepartment',{id:this.EditdepartmentID,name:this.Editdepartmentname});
         
          this.$swal({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();

        this.close();
        document.getElementById('close').click();



        },
        fetchDeprt(){
          var dt=this.$store.dispatch('DeptList')
          //fetchDepart

         var list= dt;
         console.log('==========list',list.DeptList);

        },
        submitForm(){
          if(this.departmentname)
         {
            
            this.tableData= this.$store.dispatch('AddDepartment',this.departmentname);

            this.departmentname='';
            document.getElementById('close').click();

            this.close();
            document.getElementById('close').click();

              }
         else
         {
          this.$swal({
            text: 'Please Enter Department Name',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'middle'
        });

         }

        },
     
      }
   
  }
  </script>
<style  scoped>
.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

</style>
<script>

</script>