<template>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">User type</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">User type</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
      
         <!-- /.card -->
         <div class="card">
          <div class="card-header">
            <h3 class="card-title">Users List</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add UserType</button>

           
            
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <div class="row">
              <table id="tableComponent" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>User Type</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="value in fetchDepart.Usertype" :key="value">
                        <td>{{value.utid}}</td>
                        <td>{{value.type}}</td>
                        <td class="text-center"><button type="button"  data-toggle="modal" data-target="#modal-default" @click="editusertype(value.utid)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button></td>
                    </tr>

                  </tbody>
                
                </table>
             
            </div>
            <!-- /.row -->

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          
          </div>
        </div>
    </div>
    
 </section>

 <div class="modal fade" id="modal-default-addDepart">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add UserType</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form v-on:submit.prevent="submitFormUserType" >

                  <div class="card-body">
                    <div class="form-group">
                      <label for="exampleInputEmail1">User Type Name</label>
                      <input type="text" v-model="UserTypename" class="form-control" id="usertype" placeholder="Enter user type Name" require>
                    </div>

                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                  </form>
              
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

 <div class="modal fade " :show="isModalVisible" id="modal-default" @close="showModal = false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit User type</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editSubmitUsertype">

                <div class="card-body">
                  <div class="form-group">
                    <label for="exampleInputEmail1">User Type Name</label>
                    <input type="text" v-model="EditUserTypename" class="form-control" id="depsname" placeholder="Enter department Name" require>
                  </div>
                  
              
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
            
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- /.modal -->   
</template>

<script>
     import { mapGetters,mapActions } from "vuex"

    export default {
        data() {
          return {
            isModalVisible: false,
            UserTypename:'',
            EditUserTypename:'',
            EditUserTypeID:'',
            tableData: [],
             
          }
      },
      computed: {
    ...mapActions(['fetchUserType']),
    ...mapGetters(['fetchDepart']),
      },
      mounted() {
        this.fetchUsertypes()
      },
      methods:{
        close() {
        this.$emit('close');
      },
      async editusertype(id){
        this.isModalVisible = true;

           await this.$store.dispatch('GetIdwiseUserType',id);
            this.EditUserTypename=this.fetchDepart.editUserType.type;
            this.EditUserTypeID=this.fetchDepart.editUserType.utid;
        },
        async editSubmitUsertype(){
            await this.$store.dispatch('updateUsertype',{utid:this.EditUserTypeID,type:this.EditUserTypename});
           
            this.$swal({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();

        this.isModalVisible = false;
        this.close();
        document.getElementById('close').click();


        },
        fetchUsertypes(){
            this.$store.dispatch('fetchUserType')

        },
        submitFormUserType(){
        if(this.UserTypename)
         {
            this.$store.dispatch('addUserType',this.UserTypename);
            this.UserTypename='';
            document.getElementById('close1').click();

            this.isModalVisible = false;
            document.getElementById('close').click();

            this.$swal({
            text: 'Successfully Inserted record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });


         }
         else
         {
                this.$swal({
                            text: 'Please Enter user Type Name',
                            target: '#custom-target',
                            customClass: {
                            container: 'position-absolute'
                            },
                            toast: true,
                            position: 'middle'
                        });
         }
        }
      }
     
        
    }
</script>

<style lang="scss" scoped>

</style>