<template>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Users</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Users</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
     
      <div class="col-sm-12 card ">
        <div class="card-header">
          <h3 class="card-title">Users List</h3>
          <div class="card-tools">
              <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add User</button>
            </div>
            </div>

        
        <!-- /.card-header -->
        <div class="col-sm-12 card-body">
          <div class="row">
           
              <!-- general form elements -->

              <table id="tableComponent" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>email</th>
                    <th>mobile</th>
                    <!-- <th>Department</th> -->
                    <!-- <th>Usertype</th> -->
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="value in fetchUsers" :key="value.userId">
                        <td>{{value.userId}}</td>
                        <td>{{value.name}}</td>
                        <td>{{value.email}}</td>
                        <td>{{value.mobile}}</td>
                        <!-- <td>{{value.department}}</td> -->
                        <!-- <td>{{value.userType}}</td> -->
                        <td class="text-center"><button type="button"  data-toggle="modal" data-target="#modal-default" @click="editusersdata(value.userId)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button></td>

                    </tr>

                  </tbody>
                
                </table>
              <!-- /.card -->
              <!-- <pagination :records="fetchUsers.length" v-model="page" :per-page="perPage" @paginate="callback">
              </pagination> -->
           
          </div>
          <!-- /.row -->
        </div>
        
      </div>


      <!-- /.card-body -->
      <div class="card-footer"></div>

      <!-- /.card-header -->
    </div>
  </section>
  <div class="modal fade" id="modal-default-addDepart">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add User</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form v-on:submit.prevent="submitFormUsers" ref="textareaform">
                <div class="card-body row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        v-model="name"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email Address</label>
                      <input
                        type="text"
                        v-model="email"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Password</label>
                      <input
                        type="text"
                        v-model="password"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Mobile</label>
                      <input
                        type="text"
                        v-model="mobile"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">department</label>
                     
                      <select
                        v-model="department"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                       <option v-for="valuedept in fetchDepart.DeptList" :key="valuedept.name">{{valuedept.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">User type</label>
                      <select
                        v-model="usertype"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                      <option v-for="valuedept in fetchDepart.Usertype" :key="valuedept.type">{{valuedept.type}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </form>
              
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
     <!-- Main content -->
     <div class="modal fade" id="modal-default">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Users</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateusers">
                <div class="card-body row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        v-model="editname"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Email Address</label>
                      <input
                        type="text"
                        v-model="editemail"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Mobile</label>
                      <input
                        type="text"
                        v-model="editmobile"
                        class="form-control"
                        id="depsname"
                        placeholder="Enter department Name"
                        require
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">department</label>
                     
                      <select
                        v-model="editdepartment"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                       <option v-for="valuedept in fetchDepart.DeptList" :key="valuedept.name">{{valuedept.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">User type</label>
                      <select
                        v-model="editusertype"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                      <option v-for="valuedept in fetchDepart.Usertype" :key="valuedept.type">{{valuedept.type}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Password</label>
                      <input
                        type="text"
                        v-model="editpassword"
                        class="form-control"
                        id="editpassword"
                        placeholder="Enter update password"
                        require
                      />
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
</template>

<script>
import { mapGetters,mapActions } from "vuex"
// import Pagination from 'v-pagination-3';

export default {
  data() {
          return {
            name:'',
            email:'',
            mobile:'',
            password:'',
            department:'',
            usertype:'',
            editID:'',
            editname:'',
            editemail:'',
            editmobile:'',
            editpassword:'',
            editdepartment:'',
            editusertype:'',
            tableData: [],
            page: 1,
            perPage: 5,
            records: []
          
          }
      },
      components: {
        //  Pagination
        },
      computed: {
    ...mapActions(['GetUsers','fetchUserType','DeptList']),
    ...mapGetters(['fetchUsers','fetchDepart']),
  
    maintest1() {
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      return this.records.slice(startIndex, endIndex);
    }

      },
      mounted() {
        this.UsersList(),
        this.maintest()
      },
      methods:{
       async maintest() {
        await  this.UsersList();
          console.log('=====*===',this.$store.getters['fetchUsers']);
      const startIndex = this.perPage * (this.page - 1);
      const endIndex = startIndex + this.perPage;
      this.fetchUsers.slice(startIndex, endIndex);
    },
        callback: function(page) {
        console.log('---yo---',page)
      // no need for callback here since you have all your data loaded already
    },
        close() {
        this.$emit('close');
      },
        async updateusers(){
          let payload={
            userId:this.editID,
            name:this.editname,
            email:this.editemail,
            mobile:this.editmobile,
            department:this.editdepartment,
            usertype:this.editusertype,
            password:this.editpassword
          }
          await this.$store.dispatch('updateUsersdetails',payload);

          this.$swal({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();

        this.close();
        document.getElementById('close').click();
        },
        async editusersdata(id){
            await this.$store.dispatch('getUsersdetails',id);
            this.editID=this.fetchDepart.edituser.userId;
            this.editname=this.fetchDepart.edituser.name;
            this.editemail=this.fetchDepart.edituser.email;
            this.editmobile=this.fetchDepart.edituser.mobile;
            this.editdepartment=this.fetchDepart.edituser.department;
            this.editusertype=this.fetchDepart.edituser.userType;
            this.editpassword=this.fetchDepart.edituser.password;

        },
        UsersList(){
          this.records=this.fetchUsers;
          this.$store.dispatch('DeptList','fetchUserType');
          this.$store.dispatch('fetchUserType');
          this.$store.dispatch('GetUsers');
          setTimeout(() => {
            this.records=this.$store.getters['fetchUsers'];
            console.log('=====*===',this.$store.getters['fetchUsers']);
          }, 1000);

        },
        submitFormUsers(){
          let dt='';
          let payload={
            sDate:dt,
            name:this.name,
            email:this.email,
            mobile:this.mobile,
            department:this.department,
            usertype:this.usertype,
            password:this.password,
            
          }
          this.$store.dispatch('InsertUsers',payload);

          this.$swal({
            text: 'Successfully Inserted record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

          document.getElementById('close1').click();

          this.close();
          document.getElementById('close').click();



        }
      }
};
</script>

<style lang="scss" scoped></style>
