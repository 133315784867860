<template>
     <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Projects</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Projects</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>

    <section class="content">
      <div class="container-fluid">
      
         <!-- /.card -->
         <div class="card">
          <div class="card-header">
            <h3 class="card-title">Projects List</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add Project</button>            
            </div>
          </div>
          <!-- /.card-header -->
          <div class="card-body">
            <div class="row" >
              <table id="tableComponent" class="table table-bordered table-striped">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Project Name</th>
                    <th>Due Date</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, index) in fetchDepart.projects" :key="value">
                        <td>{{index+1}}</td>
                        <td>{{value.projectName}}</td>
                        <td>{{moment(value.duedate)}}</td>
                        <td class="text-center"><button type="button"  data-toggle="modal" data-target="#modal-default" @click="getProjectdetails(value.id)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button></td>
                      
                    </tr>
                   
                  </tbody>
                
                </table>
             
            </div>
            <!-- /.row -->

          </div>
          <!-- /.card-body -->
          <div class="card-footer">
          
          </div>
        </div>
    </div>
    
 </section>
 <div class="modal fade" id="modal-default-addDepart">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add Project</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="submitProject" >

                  <div class="card-body row">

                    <!-- 
                  <div class="col-sm-6">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Super Project</label>
                            <select
                        v-model="subproject"
                        class="form-control select2bs4"
                        style="width: 100%"
                        >
                        <option v-for="valuedept in fetchSubproject" :value="valuedept.id" :key="valuedept.id">{{valuedept.projectname}}</option>
                        </select>
                        </div>
                  </div> -->
                      <div class="col-sm-6">
                          <div class="form-group">
                              <label for="exampleInputEmail1">Project</label>
                              <input type="text" v-model="project" class="form-control" id="usertype" placeholder="Enter user type Name" require>
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                              <label for="exampleInputEmail1">Description</label>
                              <textarea class="form-control" v-model="description" id="exampleFormControlTextarea1" rows="3"></textarea>
                          </div>
                      </div>
                      <!-- <div class="col-sm-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Assign Users</label>
                     
                      <select
                        v-model="user"
                        class="form-control select2bs4"
                        style="width: 100%"
                      >
                       <option v-for="valuedept in fetchUsers" :key="valuedept.userId" :value="valuedept.userId">{{valuedept.name}}</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="col-sm-6">
                        <div class="form-group">
                        <label for="exampleInputEmail1">Entity</label>
                        <select
                        v-model="entity"
                        class="form-control select2bs4"
                        style="width: 100%"
                        >
                        <option v-for="valuedept in fetchEntity" :value="valuedept.id" :key="valuedept.id">{{valuedept.entityname}}</option>
                    
                        </select>
                        </div>
                    </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                              <label for="exampleInputEmail1">Due Date</label>
                              <input type="date" v-model="duedate" class="form-control" id="datetimepicker" placeholder="Enter user type Name" require>
                          </div>
                      </div>

                  </div>
                  <!-- /.card-body -->

                  <div class="card-footer">
                    <button type="submit" class="btn btn-primary">Submit</button>
                  </div>
                  </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

 <div class="modal fade" id="modal-default">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Project</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editSubmitProject">

                <div class="card-body row">
                  
                    <div class="col-sm-6">
                        <div class="form-group">
                        <label for="exampleInputEmail1">Project</label>
                        <input type="text" v-model="editproject" class="form-control" id="Editproject" placeholder="Enter Edit project Name" require>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                        <label for="exampleInputEmail1">Description</label>
                        <textarea class="form-control" v-model="Editdescription" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                    <!-- <div class="col-sm-6">
                          <div class="form-group">
                            <label for="exampleInputEmail1">Assign Users {{edituser}}</label>
                            <select class="form-control select2bs4"  v-model="edituser">
                              <option v-for="value in fetchUsers" :key="value.userId" :value="value.userId">{{value.name}}</option>
                            
                            </select>
                        </div>
                      </div> -->

                    <div class="col-sm-6">
                        <div class="form-group">
                        <label for="exampleInputEmail1">Due Date - {{this.Editduedate}}</label>
                        <input type="date" v-model="Editduedate" id="datetimepicker" class="form-control" placeholder="Enter Editduedate " require>
                        </div>
                    </div>
                  
              
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
             
              <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

</template>

<script>
    import { mapGetters,mapActions } from "vuex"
    import moment from 'moment'

    export default {
        data() {
          return {
            cnt:1,
            editprojectID:'',
            mainID:localStorage.getItem("userID"),
            project:'',
            description:'',
            duedate:'',
            editproject:'',
            Editdescription:'',
            Editduedate:'',
            EditUserTypename:'',
            EditUserTypeID:'',
            tableData: [],
            user:null,
            edituser:null,
             
          }
      },
      computed: {
    ...mapActions(['ActionSubProject','ActionEntity','fetchProjects','GetUsers']),
    ...mapGetters(['fetchDepart','fetchProject','fetchEntity','fetchSubproject','fetchUsers']),
      },
      mounted() {
        this.fetchprojectdata()
      },
      components: {
    },
      methods:{
        moment: function (date) {
        if(date){
          return moment(date).format('DD-MM-Y');
        }
    },
        close() {
        this.$emit('close');
      },
        editSubmitProject(){
       
            let payload={
                id:this.editprojectID,
                userName:this.mainID,
                projectName:this.editproject,
                disc:this.Editdescription,
                duedate:this.Editduedate,
                sdate:null,
                entyid:null,
                sprojectid:null,
            }

           this.$store.dispatch('updateProject',payload);

           this.$swal({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();
        this.close();
        document.getElementById('close').click();


        },
        submitProject(){
              let payload={
                userName:this.mainID,
                projectName:this.project,
                disc:this.description,
                duedate:this.duedate,
                sdate:null,
                entyid:this.entity,
                sprojectid:this.subproject,
            }

            console.log('***',payload);

          this.$store.dispatch('insertProject',payload);

            this.$swal({
            text: 'Successfully Inserted record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });
        


            document.getElementById('close1').click();
        this.close();
        document.getElementById('close').click();


        },
        getProjectdetails(id){
            this.$store.dispatch('getProjectID',id);
            setTimeout(() => {
                console.log('---789',this.fetchDepart.editProject.id);
                this.editproject=this.fetchDepart.editProject.projectName;
                this.editprojectID=this.fetchDepart.editProject.id;
                this.Editdescription=this.fetchDepart.editProject.disc;

              //  this.editentity=this.fetchDepart.editProject.entyid;
              //  this.editsubproject=this.fetchDepart.editProject.sprojectid;
              
              var result = this.fetchDepart.editProject.asign;
              this.edituser=result;

              // this.edituser=this.fetchDepart.editProject.asign;
              // alert(this.edituser)

                this.Editduedate=moment(String(this.fetchDepart.editProject.duedate)).format('YYYY-MM-DD');
                // console.log('---789',moment(String(this.fetchDepart.editProject.duedate)).format('MM/DD/YYYY'));
            }, 200);
        },
        async fetchprojectdata(){
           await this.$store.dispatch('fetchProjects',localStorage.getItem('userID'));
            this.$store.dispatch('ActionEntity');
            this.$store.dispatch('ActionSubProject');
            this.$store.dispatch('GetUsers');
        }
    }
    }
</script>