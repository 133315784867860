import { createWebHashHistory, createRouter } from "vue-router";
import TmsLogin from "../views/TmsLogin.vue";
import Dashboard from "../views/UserDashboard.vue";
import DepartmentTMS from "../views/DepartmentTMS.vue";
import Users from "../views/UsersTms.vue";
import UsersType from "../views/UsersTypeTms.vue";
import projectsTMS from "../views/projectsTMS.vue";
import TaskAssignTMS from "../views/TaskAssignTMS.vue";
import Demo from "../views/demoTMS.vue";
import Fullcalendar from "../views/UserCalendarTMS.vue";
import TmsRegister from "../views/RegisterTMS.vue";
// directore
// import DirectorDashboard from '../views/Directore/DirectorDashboard.vue';
// import EntityDashboard from '../views/Directore/EntityDirectore.vue';
// end diretore
// manager

// import ManagerDashboard from "../views/manager/MdashboardTMS.vue"
// import ManagerSubProjects from "../views/manager/SubProjectsTMS.vue"
// import ManagerTeamView from "../views/manager/ManagerTeamView.vue";
// import ManagerKanBanView from "../views/manager/ManagerKanBanView.vue";

// ending manager


import NotFound from "../views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "TmsLogin",
        component: TmsLogin,
    },
    {
      path: "/register",
      name: "TmsRegister",
      component: TmsRegister,
  },
    {
      path: "/full-calendar",
      name: "full-calendar",
      component: Fullcalendar,
      beforeEnter (to, from, next) {
          if (localStorage.getItem("isLoggedIn")==='true') {
            next()
          } else {
            next('/')
          }
        },
      meta: {
          reload: true,
        },
  },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      beforeEnter (to, from, next) {
          if (localStorage.getItem("isLoggedIn")==='true') {
            next()
          } else {
            next('/')
          }
        },
      meta: {
          reload: true,
        },
  },
  {
    path: "/department",
    name: "DepartmentTMS",
    component: DepartmentTMS,
    beforeEnter (to, from, next) {
        if (localStorage.getItem("isLoggedIn")==='true') {
          next()
        } else {
          next('/')
        }
      },
    meta: {
        reload: true,
      },
},

{
  path: "/users",
  name: "Users",
  component: Users,
  beforeEnter (to, from, next) {
      if (localStorage.getItem("isLoggedIn")==='true') {
        next()
      } else {
        next('/')
      }
    },
  meta: {
      reload: true,
    },
},
{
  path: "/users-type",
  name: "UsersType",
  component: UsersType,
  beforeEnter (to, from, next) {
      if (localStorage.getItem("isLoggedIn")==='true') {
        next()
      } else {
        next('/')
      }
    },
  meta: {
      reload: true,
    },
},
{
  path: "/projects",
  name: "projectsTMS",
  component: projectsTMS,
  beforeEnter (to, from, next) {
      if (localStorage.getItem("isLoggedIn")==='true') {
        next()
      } else {
        next('/')
      }
    },
  meta: {
      reload: true,
    },
},
{
  path: "/task-assign",
  name: "TaskAssignTMS",
  component: TaskAssignTMS,
  beforeEnter (to, from, next) {
      if (localStorage.getItem("isLoggedIn")==='true') {
        next()
      } else {
        next('/')
      }
    },
  meta: {
      reload: true,
    },
},
//   {
//     path: "/Entity",
//     name: "Entity",
//     component: EntityDashboard,
//     beforeEnter (to, from, next) {
//         if (localStorage.getItem("isLoggedIn")==='true') {
//           next()
//         } else {
//           next('/')
//         }
//       },
//     meta: {
//         reload: true,
//       },
// },


//     {
//       path: "/Manager-kanban-Board",
//       name: "Manager-kanban-Board",
//       component: ManagerKanBanView,
//       beforeEnter (to, from, next) {
//           if (localStorage.getItem("isLoggedIn")==='true') {
//             next()
//           } else {
//             next('/')
//           }
//         },
//       meta: {
//           reload: true,
//         },
//   },
//     {
//       path: "/Manager-Team",
//       name: "Manager-Team",
//       component: ManagerTeamView,
//       beforeEnter (to, from, next) {
//           if (localStorage.getItem("isLoggedIn")==='true') {
//             next()
//           } else {
//             next('/')
//           }
//         },
//       meta: {
//           reload: true,
//         },
//   },
//     {
//       path: "/Manager-Sub-Project",
//       name: "Manager-Sub-Project",
//       component: ManagerSubProjects,
//       beforeEnter (to, from, next) {
//           if (localStorage.getItem("isLoggedIn")==='true') {
//             next()
//           } else {
//             next('/')
//           }
//         },
//       meta: {
//           reload: true,
//         },
//   },
//     {
//       path: "/manager-dashboard",
//       name: "manager-Dashboard",
//       component: ManagerDashboard,
//       beforeEnter (to, from, next) {
//           if (localStorage.getItem("isLoggedIn")==='true') {
//             next()
//           } else {
//             next('/')
//           }
//         },
//       meta: {
//           reload: true,
//         },
//   },
//   {
//     path: "/Director-Entity",
//     name: "Director-Entity",
//     component: EntityDashboard,
//     beforeEnter (to, from, next) {
//         if (localStorage.getItem("isLoggedIn")==='true') {
//           next()
//         } else {
//           next('/')
//         }
//       },
//     meta: {
//         reload: true,
//       },
// },
//   {
//     path: "/Director-dashboard",
//     name: "Director-dashboard",
//     component: DirectorDashboard,
//     beforeEnter (to, from, next) {
//         if (localStorage.getItem("isLoggedIn")==='true') {
//           next()
//         } else {
//           next('/')
//         }
//       },
//     meta: {
//         reload: true,
//       },
// },
  
  
  
 
  
    {
        path: "/Demo",
        name: "Demo",
        component: Demo,
        meta: {
            reload: true,
          },
    },



    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

    
];

const router = createRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    history: createWebHashHistory(),
    routes,
});

export default router;