<template>
  <section id="accueil">
    <div class="triangle_rose"></div>
   
   
      <body class="hold-transition login-page" style="background-image: url('img/login.png');">
        <div class="login-box">
          <div class="login-logo">
            <p style="color:white"><b>Goal Tracker</b></p>
          </div>
          <!-- /.login-logo -->
          <div class="card">
            <div class="card-body login-card-body">
              <p class="login-box-msg">Login</p>

              <form  @submit.prevent="checkLogin">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="email"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-3">
                  <input
                    name="password"
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    v-model="password"
                  />
                  <div class="input-group-append">
                    <div class="input-group-text">
                      <span class="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8">
                    <div class="icheck-primary">
                      <input type="checkbox" id="remember" />
                      <label for="remember"> Remember Me </label>
                    </div>
                  </div>
                  <!-- /.col -->
                  <div class="col-4">
                    <button type="submit" class="btn btn-primary btn-block">
                      Sign In
                    </button>
                    
                  </div>
                  <!-- /.col -->
                </div>
              </form>
              <div class="social-auth-links text-center mb-3">
                 
              </div>

              <p class="mb-0">
                    <router-link  class="text-center" to="/register"> 
                       Register a new User
                  </router-link>
                </p>
              
            </div>
            <!-- /.login-card-body -->
          </div>
        </div>
        <!-- /.login-box -->
      </body>
      
    <div class="triangle_vert"></div>

   
  </section>
</template>

<script>
import { mapGetters } from "vuex"
import update from '../mixins/update'

export default {
    name:"TMSLogin",
    data() {
            return {
                // for new tasks
                loginCheck: false,
                email: '',
                password:'',
            };
        },
        mixins: [update],
        computed: {
    ...mapGetters(['fetchData'])
      },
      mounted() {
      this.mountedFun();
        console.log('mounted!')
      },  
  methods: {
    refreshApp() {
            this.updateExists = true
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    mountedFun(){
      let check= localStorage.getItem("isLoggedIn");
           // alert(check)
           setTimeout(() => {
            if(check==='true'){
              //let role=localStorage.getItem('Role');

              this.$router.push('dashboard');
              
              // if(role=='manager'){
              //   this.$router.push('Manager-dashboard');
              // }else if(role=='User'){
              // }else if(role=='Administrator'){
              //   this.$router.push('Administrator-dashboard');
              // }else if(role=='Director'){
              //   this.$router.push('Director-dashboard');
              // }else{
              //   alert('Your login failed.Please contact your system administrator OR It Department')
              //   this.$store.dispatch('logout')
              // }

             
            }else
            {
              this.$router.push('/');
            }
           }, 400);
          
    },
    
   async checkLogin()
    { 
      let payload={
        uid:this.email,
        pass:this.password
      }

         await this.$store.dispatch('login',payload);
          // await this.$store.dispatch('logincheck',1);
          // alert(this.$store.state.LoginCheck);   
          // this.$storage.setStorageSync("test-key", "testdata");
          
          setTimeout(() => {
            let check= localStorage.getItem("isLoggedIn");
           // alert(check)
            if(check==='true'){

             // let role=localStorage.getItem('Role');
              this.$router.push('dashboard');

              // if(role=='manager'){
              //   this.$router.push('Manager-dashboard');
              // }else if(role=='User'){
              // }else if(role=='Administrator'){
              //   this.$router.push('Administrator-dashboard');
              // }else if(role=='Director'){
              //   this.$router.push('Director-dashboard');
              // }else{
              //   alert('Your login failed.Please contact your system administrator OR It Department')
              //   this.$store.dispatch('logout')
              // }


            }else
            {
              console.log('error');
               this.$swal({
                    text: 'Username and Password wrong.',
                    target: '#custom-target',
                    customClass: {
                      container: 'position-absolute'
                    },
                    toast: true,
                    position: 'top'
                });

              this.$router.push('/');
            }
            
          }, 500);
      
    }
}

};
</script>
<style scoped>
body {
  padding: 0;
  margin: 0;
}

section#accueil {
  background: #ecf6f8;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}




</style>
